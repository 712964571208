/*----- 9. Banner style  -------*/

.single-banner {
  @media #{$xs-layout} {
    #banner-mobile {
      justify-content: center !important;
    }
  }

  justify-content: center;
  position: relative;
  overflow: hidden;

  a {
    background-color: #fff;
    color: $theme-color;
    font-size: 42.05px;
    img {
      width: 100%;
      transform: scale(1);
      transition: all 0.5s ease 0s;
    }
  }
  &:hover img {
    transform: scale(1.2);
  }
  .banner-content {
    position: absolute;
    content: "";
    left: 36px;
    top: 40px;
    @media #{$lg-layout} {
      left: 20px;
      top: 20px;
    }
    @media #{$md-layout} {
      left: 15px;
      top: 10px;
    }
    @media #{$xs-layout} {
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
    h3 {
      font-size: 36px;
      color: #915342;
      font-weight: 600;
      margin: 0;
      @media #{$md-layout} {
        font-size: 27px;
      }
      @media #{$xs-layout} {
        font-size: 30px;
      }
    }
    h4 {
      font-size: 18px;
      color: #010101;
      margin: 3px 0 58px;
      font-weight: 600;
      @media #{$lg-layout} {
        margin: 3px 0 25px;
      }
      @media #{$lg-layout} {
        margin: 3px 0 15px;
      }
      @media #{$md-layout} {
        margin: 3px 0 8px;
      }
      @media #{$xs-layout} {
        margin: 3px 0 20px;
      }
      span {
        color: #935644;
        font-size: 20px;
        margin: 0 0px 0 2px;
        display: inline-block;
        line-height: 1;
        position: relative;
        top: 2px;
      }
    }
    a {
      width: 28px;
      height: 28px;
      border-radius: 100%;
      border: 2px solid #97584a;
      color: #97584a;
      font-size: 14px;
      display: inline-block;
      text-align: center;
      i {
        line-height: 25px;
      }
      &:hover {
        color: $theme-color;
        border: 2px solid $theme-color;
      }
    }
    

    &--style2 {
      h3 {
        color: #1c1c1c;
      }
      a {
        color: #c61a32;
        border-color: #c61a32;
      }
    }
  }
  
}

/* banner hm4 */



.banner-single-wrapper {
  height: 30rem;
  position: relative;
  padding: 1rem;
  h4 {
    background-color: $theme-color;
    color: #fff;
    padding: 0.5rem;
    text-align: center;
    font-family: $avenir;
    font-weight: normal;
    font-size: 26.41px;
    line-height: 35.29px;
    width: 171.23px;
  }
  h3 {
    font-family: $avenir;
    font-weight: normal;
    font-size: 42.39px;
    letter-spacing: 0.1em;
    line-height: 50.86px;
    text-align: center;
    color: $secundary-color;
    background-color: #E9E9E8;
    width: fit-content;
    padding-left:1rem;
    padding-right:1rem;
  }
  .bor {
    border: 1px solid $theme-color;
    width: 100%;
    z-index: 2;
  }

 
}

.text-container-mob {
@media #{$xs-layout} {
    align-items: center;
    justify-content: end!important;
  h4{
    padding: 0.2rem;
    font-size: 20.41px;
    width: 123.23px;
  }
  h3{
    font-size:25.39px;
  }
    .single-slider{
      margin-top:1rem;
      margin-bottom:3rem;
    }
  }
}
.banner-area {
  background-color: #f7f9fb;
}

@media #{$xs-layout} {
  .banner-area.pt-80 {
    padding-top: 60px;
  }
  .save-money-area {
    .pt-100 {
      padding-top: 50px;
    }
    .pb-100 {
      padding-bottom: 50px;
    }
  }
}


